body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.topnav {
  background-color: #333;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 10px;
}

.topnav .api-select {
  padding-left: calc((100% - 1780px) / 2);
}

.mobile-modal {
  display: none;
}

@media screen and (max-width: 400px) {
  .mobile-modal {
    display: block;
    box-sizing: border-box;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    height: auto;
    max-width: 300px;
    border-radius: 16px;
    background-color: #fff;
    top: 10%;
    right: 0;
    left: 0;
    font-size: 16px;
    margin: 0 auto;
    z-index: 9999;
    position: fixed;
    padding: 15px;
  }
}


.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1 0 0;
  overflow: hidden;
}
